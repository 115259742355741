// Navigation links
export const NAV_LINKS = [
  { name: 'Home', path: '/', icon: "home" },
  { name: 'Account', path: '/account', icon: "person" },
  { name: 'Feedback', path: '/feedback', icon: "add_comment" },
  { name: 'Log Out', action: 'logout', icon: "logout" }
];

// List of available subscription plans
export const PLANS = ['free', 'standard', 'pro'];

// Features based on the plan
export const PLAN_FEATURES = {
  free: ['Essential Care'],
  standard: ['Enhanced Practice'],
  pro: ['Unlimited Charting']
};

// Helper function to get features based on the plan
export const getPlanFeatures = (plan) => {
  return PLAN_FEATURES[plan] || [];
};

// SEO-friendly content
export const SEO_CONTENT = {
  title: 'ChiroNote | AI-Powered SOAP Chiropractic Notes',
  description: 'Streamline your chiropractic practice with ChiroNote. Create efficient SOAP notes using AI technology tailored for chiropractors.',
  keywords: ['SOAP notes', 'chiropractic notes', 'AI medical scribe', 'chiropractor documentation'],
};

// SOAP note sections
export const SOAP_SECTIONS = ['Subjective', 'Objective', 'Assessment', 'Plan'];

// Common chiropractic terms
export const CHIRO_TERMS = [
  'spinal adjustment',
  'subluxation',
  'manipulation',
  'mobilization',
  'cervical spine',
  'thoracic spine',
  'lumbar spine',
  'sacroiliac joint',
];
